.profile_wrapper {
  max-width: 1200px;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -90px;
  padding-top: 90px;
}

.pagehome_infos {
  text-align: center;
  display: grid;
  gap: 20px;
}

.aboutme_wrapper {
  text-align: justify;
  flex-direction: column;
  display: flex;
  padding: 0px 12px;
}

.avatar_wrapper {
  display: flex;
  justify-content: center;
}

.avatar_container {
  width: 50%;
  border-radius: 25px;
  border: 1px dashed var(--primary);
}

.avatar_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
  // margin: 1rem 0;
  border: 3px solid rgba(255, 255, 255, 0);
}

.github_link_check_text {
  color: var(--visited-link-color);
  fill: var(--visited-link-color);
}

.github_link_check_text :hover {
  fill: var(--interactive-hovered);
}

@media all and (min-width: 781px) {
  .profile {
    gap: 80px;
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
  .aboutme_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
    padding: unset;
  }

  .pagehome_infos {
    text-align: unset;
  }
  .check-github_text {
    text-align: center;
  }

  .enter {
    display: none;
  }

  .avatar_container {
    width: 100%;
  }
}
