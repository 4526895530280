/* Projects */
#projects {
  margin-top: -90px;
  padding-top: 90px;
}

.work-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.colored-box {
  position: relative;
}

.colored-box__content {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.colored-box::before {
  content: '';
  position: absolute;
  top: 10px;
  left: -10px;
  width: 100%;
  height: 100%;
  background: linear-gradient(var(--primary), #2c4576);
  border-radius: 20px;
}

.project__image-wrapper {
  // max-width: 200px;
  margin: 0 auto;
}

.project__image {
  // max-width: 100%;
  // border: 1px solid var(--primary);
  border-radius: 20px 20px 0 0;
  height: 245px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.project__image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

.grid {
  padding-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.grid-item {
  // padding: 24px 16px 39px;
  background-color: #191a1a;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid white;
  border-radius: 20px;
  position: relative;

  &:hover {
    border: 1.5px solid var(--primary);
  }
}

.grid-item__text-wrapper {
  padding: 10px;
}

.grid-item__title {
  color: var(--primary);
  text-align: center;
}

.grid-item__description {
  margin: 8px 0;
  text-align: justify;
}

.grid-item__link {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 16px;
  gap: 8px;
  display: flex;
}
.item_link-study {
  padding-right: 6px;
}

.grid-item__link a {
  fill: var(--another-white);
}

.grid-item__link a:hover {
  fill: var(--interactive-hovered);
}

/* start grid fallback */
.grid-item {
  display: inline-block;
  width: 33%;
}
/* end grid fallback */

.project_techlist {
  margin: 0;
  display: flex;
  gap: 6px;
  flex-flow: row wrap;
  padding: 10px 0 22px;
}

.project_techlist-item {
  border-right: 1px solid lightgray;
  height: 100%;
  padding: 0 10px;
  color: var(--primary);

  &:last-child {
    border: unset;
  }
}

@supports (display: grid) {
  .grid {
    display: grid;
    /* grid-template-columns: 300px 300px 300px; fractions are better than pixels - they are responsive */
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin: 0;
  }
  .grid-item {
    /* background-color: var(--another-white); */
    width: auto;
    min-height: auto;
    margin: 0;
  }
}

@media all and (max-width: 500px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
@media all and (min-width: 500px) and (max-width: 850px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media all and (max-width: 750px) {
  .grid {
    grid-gap: 20px;
  }
  .grid-item:last-child {
    grid-column: auto / auto;
    grid-row: auto / auto;
  }
  h1 {
    font-size: var(--font-size-grid-h1);
  }
}
