.svg-wrapper {
  width: 90%;
  align-self: center;
  justify-self: center;
}
.tools-wrapper {
  margin: 100px 1rem;
  border: 1px solid var(--primary);
  border-radius: 20px;
  text-align: center;
}

.techProgress_title {
  margin-top: 2px;
  color: var(--primary);
}
.code_icons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* justify-content: center;
    flex-wrap: wrap; */
  gap: 20px;
  max-width: 840px;
  margin: 0 auto;
  padding-bottom: 12px;
}

@media all and (min-width: 500px) {
  .code_icons {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.code_icons img {
  width: 25%;
  box-shadow: 1px 1px 7px var(--grey-blueish);
  background-color: var(--another-white);
}

.icon {
  display: flex;
  gap: 10px;
  align-items: center;
}

.progressbar {
  background-color: var(--another-blue-jeans);
  border-radius: 13px;
  padding: 3px;
  width: 100px;
  /* height: 10px; */
}

.progressbar > div {
  background-color: var(--primary);
  width: 40%;
  /* Adjust with JavaScript */
  height: 10px;
  border-radius: 10px;
}
/* about me/welcome page end */
