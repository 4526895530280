#study {
  padding-top: 90px;
}

.case-study__content-wrapper {
  max-width: 1084px;
  margin: 0 auto;
  display: grid;
  gap: 4rem;
}

.case-study__list {
  display: grid;
  grid-auto-columns: 1fr;
  text-align: justify;
  gap: 4rem;
}

.case-study__list-item {
  flex-direction: column;
  align-items: center;
  display: flex;
  gap: 20px;
}
.case-study__list-item:hover {
  h2 {
    color: var(--primary);
  }
  .case-study__image {
    border-style: dashed;
  }
}
.case-study__list-challenge:hover {
  h2 {
    color: var(--primary);
  }
}
.case-study__text-wrapper:hover {
  h2 {
    color: var(--primary);
  }
}

.case-study__text-wrapper {
  text-align: justify;
  gap: 20px;
  display: grid;
}

.case-study__image {
  border-radius: 20px;
  border: 1px solid var(--primary);
  padding: 4px;
}

.text_image_wrapper {
  flex-direction: column;
  align-items: center;
  margin-top: -90px;
  padding-top: 90px;
  display: flex;
}

.text_wrapper {
  text-align: center;
  gap: 20px;
  display: grid;
}
.case-study__credits {
  text-align: left;
  margin: 0 20px;
}

/* Footer */
.page-footer {
  clear: both;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 40px 0 30px;
  gap: 2px;

  @media all and (min-width: 500px) {
    margin: 40px 0 30px;
  }
}
@media all and (min-width: 781px) {
  .case-study__list-item {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: 2fr 1fr;
    gap: 80px;
  }
  .case-study__list-item:nth-child(2n) {
    grid-template-columns: 1fr 2fr;
  }
  .case-study__list-item:nth-child(2n) > .case-study__text-wrapper {
    grid-column: 2;
  }
  .case-study__list-item:nth-child(2n) > .case-study__image-wrapper {
    grid-column: 1;
  }
  .case-study__text-wrapper {
    text-align: unset;
  }
  .text_image_wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 80px;
  }
  .text_wrapper {
    text-align: unset;
  }
  .text_image_wrapper_2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-auto-flow: dense;
    gap: 80px;
  }

  .text_image_wrapper_2 > .text_wrapper {
    grid-column: 2;
  }

  .text_image_wrapper_2 > .image_organize_wrapper_2 {
    grid-column: 1;
  }

  .case-study__credits {
    text-align: left;
    margin: 0;
  }
}
