.logo {
  display: flex;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 1rem;
  height: 90px;
  align-items: center;
}

/* navbar/page-header */
.main-navigation {
  display: flex;
  align-items: center;
}

.page-header {
  /* background-color: lightgray; */
  display: flex;
  justify-content: right;
  align-items: center;
  height: 90px;
  width: 100%;
  position: fixed;
  backdrop-filter: blur(5px);
  z-index: 50;
  padding: 0 1rem;

  @media all and (min-width: 840px) {
    justify-content: center;
  }
}

.navigation-list {
  list-style: none;
  padding: 0;
}

@media all and (min-width: 840px) {
  .navigation-list {
    display: flex;
    gap: 30px;
  }
}
