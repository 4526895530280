/* Contact */
#contact {
  padding-top: 90px;
}

.contact_wrapper {
  display: grid;
  gap: 20px;
}

.contact_infos-wrapper {
  display: grid;
  margin: 0 auto;
  place-items: center;
  gap: 30px;
}

.contacts {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

@media all and (min-width: 500px) {
  .contact_wrapper {
    gap: 80px;
  }

  .contact_infos-wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .contacts {
    gap: 70px;
    order: 2;
  }
}

#contact-form {
  display: grid;
  width: 100%;
}

@media all and (min-width: 500px) {
  #contact-form {
    grid-template-columns: 1fr;
  }
}

form {
  margin: 0;
}

.form_contact-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // max-width: 420px;
}

.input-form {
  border-radius: 8px;
  line-height: 16px;
  background-color: transparent;
  color: var(--white);
  border: 2px solid var(--primary);
  transition: all 0.3s;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
}

.input-form:focus {
  border: 2px solid var(--interactive-focus);
}

textarea {
  height: 110px;
  line-height: 150%;
  resize: vertical;
}

#contact-message {
  border: 2px solid var(--primary);
  border-radius: 5px;
  border-color: var(--primary);
}

#contact-message:focus {
  border: 2px solid var(--interactive-focus);
}

.button,
button {
  /* overwrites browser defaults and resets the border */
  border: none;
  display: inline-block;
  /* takes the font from the outer container */
  font-family: inherit;
  /* styles the button */
  background-color: var(--primary);
  color: var(--white);
  text-decoration: none;
  padding: 10px;
  /* width of the border, type, color */
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  transition: 0.2s opacity;
}

button:hover,
button:focus,
.button:hover,
.button:focus {
  /* lets the mouse appear as a hand when hovering over the button */
  cursor: pointer;
  /* reduces the opacity of the button to 80% */
  opacity: 0.8;
}

.button__secondary {
  background-color: var(--primary);
  color: var(--dark-grey);
}

.button_container {
  padding-bottom: 20px;
}

.contact_wrapper input[type="submit"] {
  text-align: center;
  width: 100%;
  background: var(--primary);
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  color: white;
  font-size: 16px;
  padding: 10px;
  transition: all 0.3s;
  margin-top: 3px;
  font-weight: 700;
}

.contact_wrapper input[type="submit"]:hover {
  background: var(--interactive-hovered);
}
