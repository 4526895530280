body {
  /* background: linear-gradient(45deg, bisque, transparent); */
  font-family: "Poppins", sans-serif;
  font-weight: var(--font-weight-thinner);
  font-size: var(--font-size-body);
  /* Sets the font size to 16px */
  line-height: 1.6;
  background-color: var(--background-black);
  color: #f4f8f8;
  /* Sets the line-height to 1.6 */
}

h1 {
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight-bigger);
}

h2 {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-bigger);
}

h3 {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-thinner);
}

a {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

a:visited {
  /* visited link */
  color: var(--primary);
}

a:hover,
a:focus {
  /* No underlining when hovering over a link */
  text-decoration: none;
  color: white;
}

a:active {
  /* selected link */
  color: var(--active-link-color);
}

.page_content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 90px;
}

.page-section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 0 2rem;

  &:first-of-type {
    padding-top: 5rem;

    @media only screen and (min-width: 768px) {
      padding-top: 0;
    }
  }
}

.section_wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  padding: 0 2rem;
}

@media only screen and (min-width: 1232px) {
  .section_wrapper {
    padding: 0;
  }
}

.yellowing {
  color: var(--primary);
}

.title_subtitle_center {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

/* Fade in Effect */
#welcome,
#projects,
#contact {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.vanta-canvas {
  opacity: 0.3;
}
