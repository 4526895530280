/* Footer */
.page-footer {
  clear: both;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 40px 0 30px;
  gap: 6px;

  @media all and (min-width: 500px) {
    margin-top: 40px;
  }
}

.find-me {
  font-weight: 600;
  margin-bottom: 0;
  color: var(--primary);
  text-align: center;
}

.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.social-media a {
  fill: var(--another-white);
  svg {
    width: 26px;
  }
}

.social-media a:hover {
  transform: translateY(-4px);

  svg {
    fill: var(--interactive-hovered);
  }
}
