*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd, ul {
  margin: 0;
}

ul[role="list"], ol[role="list"], ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

textarea {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  outline: none;
  overflow: auto;
}

:root {
  --secondary-color: #e0b354;
  --white: #fff;
  --another-white: floralwhite;
  --visited-link-color: #f6b24a;
  --active-link-color: #f6b24a;
  --dark-grey: #2b2b2b;
  --sun-color-one: #edc655;
  --sun-color-two: #fcffad;
  --sun-color-three: #f76414;
  --button-bg-color: #ffae34;
  --background-black: #191a1a;
  --primary: #ffae34;
  --white-ton: #f4f8f8;
  --interactive-hovered: #f76414;
  --interactive-focus: #edc655;
  --blue: #01bedd;
  --green: #41b3a3;
  --gold: #daaa7d;
  --grey-blueish: #9eb2c5;
  --rosegolden: #bd8c7d;
  --another-blue-jeans: #3a4660;
  --font-weight-thinner: 200;
  --font-weight-thin: 300;
  --font-weight-normal: 400;
  --font-weight-normal-big: 500;
  --font-weight-bold: 600;
  --font-weight-bigger: 700;
  --font-size-body: 14px;
  --font-size-h1: 36px;
  --font-size-h2: 26px;
  --font-size-h3: 16px;
  --font-size-grid-h1: 36px;
}

@media (min-width: 500px) {
  :root {
    --font-size-body: 16px;
    --font-size-h1: 50px;
    --font-size-h2: 28px;
    --font-size-h3: 22px;
  }
}

body {
  font-family: Poppins, sans-serif;
  font-weight: var(--font-weight-thinner);
  font-size: var(--font-size-body);
  background-color: var(--background-black);
  color: #f4f8f8;
  line-height: 1.6;
}

h1 {
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight-bigger);
}

h2 {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-bigger);
}

h3 {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-thinner);
}

a {
  color: var(--primary);
  cursor: pointer;
  text-decoration: none;
}

a:visited {
  color: var(--primary);
}

a:hover, a:focus {
  color: #fff;
  text-decoration: none;
}

a:active {
  color: var(--active-link-color);
}

.page_content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 90px;
}

.page-section {
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  display: flex;
  position: relative;
}

.page-section:first-of-type {
  padding-top: 5rem;
}

@media only screen and (min-width: 768px) {
  .page-section:first-of-type {
    padding-top: 0;
  }
}

.section_wrapper {
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
}

@media only screen and (min-width: 1232px) {
  .section_wrapper {
    padding: 0;
  }
}

.yellowing {
  color: var(--primary);
}

.title_subtitle_center {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

#welcome, #projects, #contact {
  -ms-animation: fadein 2s;
  animation: 2s fadein;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.vanta-canvas {
  opacity: .3;
}

.logo {
  z-index: 100;
  height: 90px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 1rem;
}

.main-navigation {
  align-items: center;
  display: flex;
}

.page-header {
  height: 90px;
  width: 100%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 50;
  justify-content: right;
  align-items: center;
  padding: 0 1rem;
  display: flex;
  position: fixed;
}

@media (min-width: 840px) {
  .page-header {
    justify-content: center;
  }
}

.navigation-list {
  padding: 0;
  list-style: none;
}

@media (min-width: 840px) {
  .navigation-list {
    gap: 30px;
    display: flex;
  }
}

.profile_wrapper {
  max-width: 1200px;
}

.profile {
  flex-direction: column;
  align-items: center;
  margin-top: -90px;
  padding-top: 90px;
  display: flex;
}

.pagehome_infos {
  text-align: center;
  gap: 20px;
  display: grid;
}

.aboutme_wrapper {
  text-align: justify;
  flex-direction: column;
  padding: 0 12px;
  display: flex;
}

.avatar_wrapper {
  justify-content: center;
  display: flex;
}

.avatar_container {
  width: 50%;
  border: 1px dashed var(--primary);
  border-radius: 25px;
}

.avatar_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 3px solid #fff0;
  border-radius: 25px;
}

.github_link_check_text {
  color: var(--visited-link-color);
  fill: var(--visited-link-color);
}

.github_link_check_text :hover {
  fill: var(--interactive-hovered);
}

@media (min-width: 781px) {
  .profile {
    grid-template-columns: 2fr 1fr;
    gap: 80px;
    display: grid;
  }

  .aboutme_wrapper {
    padding: unset;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
    display: grid;
  }

  .pagehome_infos {
    text-align: unset;
  }

  .check-github_text {
    text-align: center;
  }

  .enter {
    display: none;
  }

  .avatar_container {
    width: 100%;
  }
}

.svg-wrapper {
  width: 90%;
  place-self: center;
}

.tools-wrapper {
  border: 1px solid var(--primary);
  text-align: center;
  border-radius: 20px;
  margin: 100px 1rem;
}

.techProgress_title {
  color: var(--primary);
  margin-top: 2px;
}

.code_icons {
  max-width: 840px;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 0 auto;
  padding-bottom: 12px;
  display: grid;
}

@media (min-width: 500px) {
  .code_icons {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.code_icons img {
  width: 25%;
  box-shadow: 1px 1px 7px var(--grey-blueish);
  background-color: var(--another-white);
}

.icon {
  align-items: center;
  gap: 10px;
  display: flex;
}

.progressbar {
  background-color: var(--another-blue-jeans);
  width: 100px;
  border-radius: 13px;
  padding: 3px;
}

.progressbar > div {
  background-color: var(--primary);
  width: 40%;
  height: 10px;
  border-radius: 10px;
}

#projects {
  margin-top: -90px;
  padding-top: 90px;
}

.work-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.colored-box {
  position: relative;
}

.colored-box__content {
  height: 100%;
  width: 100%;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.colored-box:before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(var(--primary), #2c4576);
  border-radius: 20px;
  position: absolute;
  top: 10px;
  left: -10px;
}

.project__image-wrapper {
  margin: 0 auto;
}

.project__image {
  height: 245px;
  border-radius: 20px 20px 0 0;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.project__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.grid {
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 24px;
  display: grid;
}

.grid-item {
  height: 100%;
  background-color: #191a1a;
  border: 1px solid #fff;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.grid-item:hover {
  border: 1.5px solid var(--primary);
}

.grid-item__text-wrapper {
  padding: 10px;
}

.grid-item__title {
  color: var(--primary);
  text-align: center;
}

.grid-item__description {
  text-align: justify;
  margin: 8px 0;
}

.grid-item__link {
  text-align: right;
  gap: 8px;
  padding: 16px;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.item_link-study {
  padding-right: 6px;
}

.grid-item__link a {
  fill: var(--another-white);
}

.grid-item__link a:hover {
  fill: var(--interactive-hovered);
}

.grid-item {
  width: 33%;
  display: inline-block;
}

.project_techlist {
  flex-flow: wrap;
  gap: 6px;
  margin: 0;
  padding: 10px 0 22px;
  display: flex;
}

.project_techlist-item {
  height: 100%;
  color: var(--primary);
  border-right: 1px solid #d3d3d3;
  padding: 0 10px;
}

.project_techlist-item:last-child {
  border: unset;
}

@supports (display: grid) {
  .grid {
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0;
    display: grid;
  }

  .grid-item {
    width: auto;
    min-height: auto;
    margin: 0;
  }
}

@media (max-width: 500px) {
  .grid {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 500px) and (max-width: 850px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 750px) {
  .grid {
    grid-gap: 20px;
  }

  .grid-item:last-child {
    grid-area: auto;
  }

  h1 {
    font-size: var(--font-size-grid-h1);
  }
}

#contact {
  padding-top: 90px;
}

.contact_wrapper {
  gap: 20px;
  display: grid;
}

.contact_infos-wrapper {
  place-items: center;
  gap: 30px;
  margin: 0 auto;
  display: grid;
}

.contacts {
  grid-template-columns: 1fr;
  gap: 10px;
  display: grid;
}

@media (min-width: 500px) {
  .contact_wrapper {
    gap: 80px;
  }

  .contact_infos-wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .contacts {
    order: 2;
    gap: 70px;
  }
}

#contact-form {
  width: 100%;
  display: grid;
}

@media (min-width: 500px) {
  #contact-form {
    grid-template-columns: 1fr;
  }
}

form {
  margin: 0;
}

.form_contact-wrapper {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.input-form {
  color: var(--white);
  border: 2px solid var(--primary);
  width: 100%;
  box-sizing: border-box;
  background-color: #0000;
  border-radius: 8px;
  outline: 0;
  padding: 10px;
  line-height: 16px;
  transition: all .3s;
}

.input-form:focus {
  border: 2px solid var(--interactive-focus);
}

textarea {
  height: 110px;
  resize: vertical;
  line-height: 150%;
}

#contact-message {
  border: 2px solid var(--primary);
  border-color: var(--primary);
  border-radius: 5px;
}

#contact-message:focus {
  border: 2px solid var(--interactive-focus);
}

.button, button {
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-bottom: 2px solid #0000004d;
  padding: 10px;
  font-family: inherit;
  text-decoration: none;
  transition: opacity .2s;
  display: inline-block;
}

button:hover, button:focus, .button:hover, .button:focus {
  cursor: pointer;
  opacity: .8;
}

.button__secondary {
  background-color: var(--primary);
  color: var(--dark-grey);
}

.button_container {
  padding-bottom: 20px;
}

.contact_wrapper input[type="submit"] {
  text-align: center;
  width: 100%;
  background: var(--primary);
  cursor: pointer;
  color: #fff;
  border: 0;
  border-radius: 5px;
  margin-top: 3px;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  transition: all .3s;
}

.contact_wrapper input[type="submit"]:hover {
  background: var(--interactive-hovered);
}

#study {
  padding-top: 90px;
}

.case-study__content-wrapper {
  max-width: 1084px;
  gap: 4rem;
  margin: 0 auto;
  display: grid;
}

.case-study__list {
  text-align: justify;
  grid-auto-columns: 1fr;
  gap: 4rem;
  display: grid;
}

.case-study__list-item {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
}

.case-study__list-item:hover h2 {
  color: var(--primary);
}

.case-study__list-item:hover .case-study__image {
  border-style: dashed;
}

.case-study__list-challenge:hover h2, .case-study__text-wrapper:hover h2 {
  color: var(--primary);
}

.case-study__text-wrapper {
  text-align: justify;
  gap: 20px;
  display: grid;
}

.case-study__image {
  border: 1px solid var(--primary);
  border-radius: 20px;
  padding: 4px;
}

.text_image_wrapper {
  flex-direction: column;
  align-items: center;
  margin-top: -90px;
  padding-top: 90px;
  display: flex;
}

.text_wrapper {
  text-align: center;
  gap: 20px;
  display: grid;
}

.case-study__credits {
  text-align: left;
  margin: 0 20px;
}

.page-footer {
  clear: both;
  text-align: center;
  flex-direction: column;
  gap: 2px;
  margin: 40px 0 30px;
  display: flex;
}

@media (min-width: 500px) {
  .page-footer {
    margin: 40px 0 30px;
  }
}

@media (min-width: 781px) {
  .case-study__list-item {
    grid-auto-flow: dense;
    grid-template-columns: 2fr 1fr;
    gap: 80px;
    display: grid;
  }

  .case-study__list-item:nth-child(2n) {
    grid-template-columns: 1fr 2fr;
  }

  .case-study__list-item:nth-child(2n) > .case-study__text-wrapper {
    grid-column: 2;
  }

  .case-study__list-item:nth-child(2n) > .case-study__image-wrapper {
    grid-column: 1;
  }

  .case-study__text-wrapper {
    text-align: unset;
  }

  .text_image_wrapper {
    grid-template-columns: 2fr 1fr;
    gap: 80px;
    display: grid;
  }

  .text_wrapper {
    text-align: unset;
  }

  .text_image_wrapper_2 {
    grid-template-columns: 1fr 2fr;
    grid-auto-flow: dense;
    gap: 80px;
    display: grid;
  }

  .text_image_wrapper_2 > .text_wrapper {
    grid-column: 2;
  }

  .text_image_wrapper_2 > .image_organize_wrapper_2 {
    grid-column: 1;
  }

  .case-study__credits {
    text-align: left;
    margin: 0;
  }
}

.page-footer {
  clear: both;
  text-align: center;
  margin-top: 40px 0 30px;
  flex-direction: column;
  gap: 6px;
  display: flex;
}

@media (min-width: 500px) {
  .page-footer {
    margin-top: 40px;
  }
}

.find-me {
  color: var(--primary);
  text-align: center;
  margin-bottom: 0;
  font-weight: 600;
}

.social-media {
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;
}

.social-media a {
  fill: var(--another-white);
}

.social-media a svg {
  width: 26px;
}

.social-media a:hover {
  transform: translateY(-4px);
}

.social-media a:hover svg {
  fill: var(--interactive-hovered);
}

#menuToggle input {
  width: 40px;
  height: 32px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
  display: block;
  position: absolute;
  top: -7px;
  left: -5px;
}

@media (max-width: 840px) {
  #menuToggle {
    height: 22px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    display: block;
    position: relative;
  }

  #menuToggle a {
    text-decoration: none;
  }

  #menuToggle input {
    width: 40px;
    height: 32px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
    display: block;
    position: absolute;
    top: -7px;
    left: -5px;
  }

  #menuToggle span {
    width: 33px;
    height: 4px;
    background: var(--primary);
    z-index: 1;
    transform-origin: 4px 0;
    border-radius: 3px;
    margin-bottom: 5px;
    transition: transform .5s cubic-bezier(.77, .2, .05, 1), background .5s cubic-bezier(.77, .2, .05, 1), opacity .55s;
    display: block;
    position: relative;
  }

  #menuToggle span:first-child {
    transform-origin: 0 0;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0 100%;
  }

  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg)translate(-2px, -1px);
  }

  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0)scale(.2);
  }

  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg)translate(0, -1px);
  }

  #menu {
    background-color: var(--background-black);
    width: 100%;
    height: 100vh;
    text-align: right;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0 0;
    padding: 125px 50px 50px;
    list-style-type: none;
    transition: transform .5s cubic-bezier(.77, .2, .05, 1);
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(0, -100%);
  }

  #menu li {
    padding: 10px 0;
    font-size: 22px;
  }

  #menuToggle input:checked ~ ul {
    transform: none;
  }
}

/*# sourceMappingURL=index.0922b5a5.css.map */
