@import "reset.scss";
@import "variables.scss";
@import "general.scss";
@import "sections/header.scss";
@import "sections/welcome.scss";
@import "sections/knowledge.scss";
@import "sections/projects.scss";
@import "sections/contact.scss";
@import "sections/studyCase.scss";
@import "footer.scss";
@import "menu.scss";
